@import './flex.scss';

@media only screen and (max-width: 767px) {
	.hide-small {
		display: none !important;
	}
}

@media only screen and (min-width: 768px) {
	.hide-large {
		display: none !important;
	}
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1;
}

@media only screen and (min-width: 768px) {
	.sticky {
		position: static;
	}
}

.visibility-hidden {visibility: hidden;}

@media only screen and (min-width: 768px) {
	.mw768 {
		&-visibility-hidden {visibility: hidden;}
	}
}

.display-none {
	display: none;
}

@media only screen and (min-width: 768px) {
	.mw768 {
		&-display-none {display: none;}
	}
}

.display-block {
	display: block;
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.position-fixed {
	position: fixed;
}

.position-sticky {
	position: sticky;
}

.cursor-pointer {
	cursor: pointer;
}

.white-space-nowrap {
	white-space: nowrap;
}

.border-1px-solid {
	border: 1px solid;
}

.border-top-1px-solid {
	border-top: 1px solid;
}

.border-bottom-1px-solid {
	border-bottom: 1px solid;
}

.border-bottom-1px-dashed {
	border-bottom: 1px dashed;
}

.border-bottom-1px-dotted {
	border-bottom: 1px dotted;
}

.border-1px-solid-black {
	border: 1px solid black;
}

.border-bottom-1px-solid-black {
    border-bottom: 1px solid black;
}

.border-bottom-1px-dotted-black {
	border-bottom: 1px dotted black;
}

.border-top-1px-dotted-black {
	border-top: 1px dotted black;
}

.border-1px-dotted-black {
	border: 1px dotted black;
}

.font-weight-bold {
	font-weight: bold;
}

.font-style-italic {
	font-style: italic;
}

.margin-0-auto {
	margin: 0 auto;
}
@media only screen and (min-width: 768px) {
	.mw768 {
		&-margin-0-auto {margin: 0 auto;}
	}
}

.margin-0dot5rem-0 {
	margin: 0.5rem 0;
}

.margin-top-1rem {
	margin-top: 1rem;
}

.margin-top-1dot5rem {
	margin-top: 1.5rem;
}

.margin-top-0dot2rem {
	margin-top: 0.2rem;
}

.margin-top-0dot4rem {
	margin-top: 0.4rem;
}

.margin-top-0dot5rem {
	margin-top: 0.5rem;
}

.margin-left-0dot5rem {
	margin-left: 0.5rem;
}

.margin-left-0dot25rem {
	margin-left: 0.25rem;
}

.margin-right-0dot5rem {
	margin-right: 0.5rem;
}

.margin-right-0dot25rem {
	margin-right: 0.25rem;
}

.margin-right-0dot2rem {
	margin-right: 0.2rem;
}

.margin-top-2rem {
	margin-top: 2rem;
}

.margin-top-2dot5rem {
	margin-top: 2.5rem;
}

.margin-top-3rem {
	margin-top: 3rem;
}

.margin-top-3dot5rem {
	margin-top: 3.5rem;
}

.margin-top-4rem {
	margin-top: 4rem;
}

.margin-bottom-1rem {
	margin-bottom: 1rem;
}

.margin-bottom-0dot5rem {
	margin-bottom: 0.5rem;
}

.opacity-0 {
	opacity: 0;
}

@media only screen and (min-width: 768px) {
	.mw768 {
		&-opacity-0 {opacity: 0;}
	}
}

.opacity-0dot2 {
	opacity: 0.2;
}

.opacity-0dot4 {
	opacity: 0.4;
}

@media only screen and (min-width: 768px) {
	.mw768 {
		&-opacity-0dot4 {opacity: 0.4;}
	}
}

.opacity-0dot6 {
	opacity: 0.6;
}

.opacity-0dot8 {
	opacity: 0.8;
}

.font-size-2rem {
	font-size: 2rem;
}

.font-size-2dot5rem {
	font-size: 2.5rem;
}

.font-size-1dot2rem {
	font-size: 1.2rem;
}

.font-size-1dot4rem {
	font-size: 1.4rem;
}

.font-size-0dot8rem {
	font-size: 0.8rem;
}

.padding-0dot4rem-0dot8rem {
	padding: 0.4rem 0.8rem;
}

.padding-0dot5rem-0 {
	padding: 0.5rem 0;
}

.padding-1rem {
	padding: 1rem;
}

.padding-2rem {
	padding: 2rem;
}

.padding-1dot5rem {
	padding: 1.5rem;
}

.padding-0dot5rem {
	padding: 0.5rem;
}

.padding-0dot2rem {
	padding: 0.2rem;
}

.padding-1rem-0 {
	padding: 1rem 0;
}

.padding-1rem-1dot5rem {
	padding: 1rem 1.5rem;
}

.margin-left-1rem {
	margin-left: 1rem;
}

.margin-right-1rem {
	margin-right: 1rem;
}

.padding-left-1rem {
	padding-left: 1rem;
}

.padding-left-0dot5rem {
	padding-left: 0.5rem;
}

.padding-right-1rem {
	padding-right: 1rem;
}

.padding-right-1dot5rem {
	padding-right: 1.5rem;
}

.padding-top-1rem {
	padding-top: 1rem;
}

.padding-top-1dot5rem {
	padding-top: 1.5rem;
}

.padding-bottom-1rem {
	padding-bottom: 1rem;
}

.padding-bottom-0dot5rem {
	padding-bottom: 0.5rem;
}

.padding-bottom-0dot2rem {
	padding-bottom: 0.2rem;
}

.padding-right-0dot5rem {
	padding-right: 0.5rem;
}

.padding-top-0dot5rem {
	padding-top: 0.5rem;
}

.text-decoration-underline {
	text-decoration: underline;
}

.text-decoration-line-through {
	text-decoration: line-through;
}

.text-align-start {
	text-align: start;
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.max-width-100percent {max-width: 100%;}
.max-height-100percent {max-height: 100%;}
.max-height-20percent {max-height: 80%;}
.width-100percent {width: 100%;}
.width-80percent {width: 80%;}
.width-25percent {width: 25%;}
.width-20percent {width: 20%;}
.width-50percent {width: 50%;}
.width-40percent {width: 40%;}
.width-max-content {
	width: max-content;
}
.height-400percent {height: 400%;}
.height-200percent {height: 200%;}
.height-100percent {height: 100%;}
.height-80percent {height: 80%;}
.height-60percent {height: 60%;}
.height-50percent {height: 50%;}
.height-40percent {height: 40%;}
.height-25percent {height: 25%;}
.height-20percent {height: 20%;}
.height-100vh {height: 100vh;}
.min-height-100dvh {min-height: 100dvh;}

.width-inherit {width: inherit;}

.overflow-wrap-break-word {
	overflow-wrap: break-word;
}

.line-height-1dot6rem {
	line-height: 1.6rem;
}

.object-fit-cover {object-fit: cover;}
.object-fit-contain {object-fit: contain;}
.object-fit-fill {object-fit: fill;}
.object-fit-scale-down {object-fit: scale-down;}


.border-radius-50percent {border-radius: 50%;}

.overflow-hidden {overflow: hidden;}
.overflow-auto {overflow: auto;}
.overflow-x-auto {overflow-x: auto;}
.overflow-y-auto {overflow-y: auto;}

.display-inline {display: inline;}

.display-table {display: table;}
.display-table-row {display: table-row;}
.display-table-cell {display: table-cell;}
.empty-cells-show {empty-cells: show;}
.vertical-align-middle {vertical-align: middle;}
.text-align-end {text-align: end};

.display-grid {display: grid;}
.grid-cols-1 {grid-template-columns: repeat(1, minmax(0, 1fr));}
.grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr));}
.grid-cols-3 {grid-template-columns: repeat(3, minmax(0, 1fr));}
.grid-cols-4 {grid-template-columns: repeat(4, minmax(0, 1fr));}
.grid-cols-5 {grid-template-columns: repeat(5, minmax(0, 1fr));}
.grid-cols-6 {grid-template-columns: repeat(6, minmax(0, 1fr))};
.grid-cols-7 {grid-template-columns: repeat(7, minmax(0, 1fr))};
.col-span-2	{grid-column: span 2 / span 2};
.col-span-3	{grid-column: span 3 / span 3};
.col-span-4	{grid-column: span 4 / span 4};
.col-span-5	{grid-column: span 5 / span 5};
.col-span-6	{grid-column: span 6 / span 6};
.col-span-7	{grid-column: span 7 / span 7};

.text-overflow-ellipsis {text-overflow: ellipsis;}

.rotate {
	transition: transform 0.2s linear;
}

.rotate-180deg {
	transform: rotate(180deg);
	transition: transform 0.2s linear;
}

.text-transform-uppercase {
	text-transform: uppercase;
}

.text-transform-capitilize {
	text-transform: capitalize;
}

.outline-4px-solid {
	outline-width: 4px;
	outline-style: solid;
}

@media only screen and (min-width: 768px) {
	.mw768 {
		&-display-block {display: block;}
		&-display-grid {display: grid;}
		&-grid-cols-1 {grid-template-columns: repeat(1, minmax(0, 1fr));}
		&-grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr));}
		&-grid-cols-3 {grid-template-columns: repeat(3, minmax(0, 1fr));}
		&-grid-cols-4 {grid-template-columns: repeat(4, minmax(0, 1fr));}
		&-grid-cols-5 {grid-template-columns: repeat(5, minmax(0, 1fr));}
		&-grid-cols-6 {grid-template-columns: repeat(6, minmax(0, 1fr))};
		&-grid-cols-7 {grid-template-columns: repeat(7, minmax(0, 1fr))};
		&-col-span-2 {grid-column: span 2 / span 2};
		&-col-span-3 {grid-column: span 3 / span 3};
		&-col-span-4 {grid-column: span 4 / span 4};
		&-col-span-5 {grid-column: span 5 / span 5};
		&-col-span-6 {grid-column: span 6 / span 6};
		&-col-span-7 {grid-column: span 7 / span 7};

		&-border-1px-solid {border: 1px solid;}

		&-visibility-visible {visibility: visible;}
	}
}