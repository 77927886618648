@import '../../../components/theme/ogle-colors.scss';

.ogle {
    .pages {
        .warp {
            .space {
                min-height: 100vh;

                .block {
                    margin: 0;
                }
              
                .max-width-50rem {
                      max-width: 50rem;
                }
                
                hr {
                    border: none;
                    height: 1px;
                }
                
                .e__accent {
                    font-size: 1.6rem;
                }

                .space__poster-img {
                    height: 100%;
                    width: 100%;
                }
                
                @media only screen and (min-width: 768px) {
                    .e__accent {
                        font-size: 2.4rem;
                    }
                }
                
                .e__title {
                    font-size: 2rem;
                    text-align: center;
                }
                .marked img {
                    max-height: 100%;
                    max-width: 100%;
                }
                .marked p {
                    padding-bottom: 1rem;
                }
                .marked hr {
                    margin-bottom: 1rem;
                }
                .marked strong {
                    font-weight: bold;
                }
                
                .view .marked a {
                    color: map-get($colors, primary-500);
                }
              
                .gap-0dot5rem-1rem {
                    gap: 0.5rem 1rem;
                }

                .flex-order-1 {
                    order: 1;
                }
                .flex-order-2 {
                    order: 2;
                }
                .width-7rem {
                    width: 7rem;
                }
                .box-shadow-xs {
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                }

                .min-height-2dot625rem {
                    min-height: 2.625rem;
                }

                .buy-btn-animation--forward {
                    transition: border 50ms ease-out 50ms;
                    animation-name: scaleBtn;
                    animation-duration: 50ms;
                    animation-timing-function: ease-in-out;
                    animation-play-state: paused;
                }

                .buy-btn-animation--backwards {
                    transition: border 100ms ease-out 100ms;
                }

                .buy-btn-animation--play {
                    animation-play-state: running;
                }

                .add-to-cart--visible {
                    opacity: 1;
                    visibility: visible;
                    width: auto;
                    transition: opacity 100ms ease-out 100ms, visibility 100ms ease-out 100ms;
                }

                .add-to-cart--hidden {
                    opacity: 0;
                    width: 0;
                    visibility: hidden;
                    transition: all 50ms ease-out 0;
                    position: absolute;
                }

                .decrement-endpoint--visible {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    position: absolute;
                    right: calc(100% - 2rem);
                    transition: opacity 100ms ease-in-out 50ms, visibility 100ms ease-in-out 50ms, transform 100ms ease-in-out 50ms;
                }

                .decrement-endpoint--hidden {
                    opacity: 0;
                    visibility: hidden;
                    transform: translateX(50%);
                    right: 50%;
                    position: absolute;
                    transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out, transform 100ms ease-in-out;
                    animation: 100ms ease-in-out hideRight;
                    pointer-events: none;
                }

                .increment-endpoint--visible {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    position: absolute;
                    left: calc(100% - 2rem);
                    transition: opacity 100ms ease-in-out 50ms, visibility 100ms ease-in-out 50ms, transform 100ms ease-in-out 50ms;
                    
                }

                .increment-endpoint--hidden {
                    opacity: 0;
                    visibility: hidden;
                    transform: translateX(-50%);
                    left: 50%;
                    position: absolute;
                    transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out;
                    animation: 100ms ease-in-out hideLeft;
                    pointer-events: none;
                }
                
                .endpoint-quantity--hidden {
                    opacity: 0;
                    visibility: hidden;
                    width: 0;
                    min-width: 0;
                    transition: opacity 100ms ease-in-out, visibility 100ms ease-in-out;
                }

                .endpoint-quantity--visible {
                    opacity: 1;
                    visibility: visible;
                    width: auto;
                    min-width: 2rem;
                    padding: 0 0.25rem;
                    transition: opacity 50ms ease-out 150ms, visibility 50ms ease-out 150ms;
                }
                .max-height-35rem {
                    max-height: 35rem;
                }

                .max-width-100vw {
                    max-width: 100vw;
                }

                .gap-1dot25rem {
                    gap: 1.25rem;
                }

                @keyframes scaleBtn {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(0.98);
                    }
                    100% {
                        transform: scale(0.98);
                    }
                }

                @keyframes hideLeft {
                    0% {
                        transform: translateX(0);
                        left: calc(100% - 2rem);
                    }
                    100% {
                        left: calc(100% - 2rem);
                        transform: translateX(0);
                    }
                }

                @keyframes hideRight {
                    0% {
                        transform: translateX(0);
                        right: calc(100% - 2rem);
                    }
                    100% {
                        right: calc(100% - 2rem);
                        transform: translateX(0);
                    }
                }

                &:has(.theme--light) {
                    background: map-get($colors, gray-50);

                    .space__header {
                        background-color: white;
                    }

                    .logo--dark {
                        display: none;
                    }

                    .buy-tickets-btn {
                        color: map-get($colors, gray-700);
                    }

                    .warp-footer{
                        border-color: map-get($colors, gray-200);
                        color: map-get($colors, gray-500);

                        a {
                            color: map-get($colors, gray-500);
                        }
                    }

                    .space__title {
                        color: map-get($colors, gray-900);
                    }

                    .space__target {
                        color: map-get($colors, gray-500);
                    }

                    .marked {
                        color: map-get($colors, gray-700);
                    }

                    .cart-btn--disabled:disabled {
                        background: map-get($colors, gray-200);
                        color: white;
                        border: none;
        
                        path {
                            stroke: white;
                        }
                    }

                    hr {
                        color: map-get($colors, gray-200);
                        background-color: map-get($colors, gray-200);
                    }

                    .space-endpoint {
                        background-color: white;
                        border-color: map-get($colors, gray-100);
                    }

                    .space-endpoint__title {
                        color: map-get($colors, gray-700);
                    }

                    .space-endpoint__date {
                        color: map-get($colors, gray-500);
                    }

                    .space-endpoint__price {
                        color: map-get($colors, gray-700);
                    }

                    .space-endpoint__btn {
                        color: map-get($colors, primary-600);

                        path {
                            stroke: map-get($colors, primary-600);
                        }
                    }

                    .space-endpoint__btn--empty {
                        border-color: map-get($colors, gray-300);
                    }

                    .space-endpoint__btn--entry {
                        border-color: map-get($colors, gray-300);
                    }

                    .space-endpoint__btn--disabled {
                        border-color: map-get($colors, gray-300);
                        pointer-events: none;
                        color: map-get($colors, gray-300);
                        box-shadow: none;
                    }

                    .space__social-icon {
                        path {
                            fill: map-get($colors, gray-400);
                        }
                    }
                }

                &:has(.theme--dark) {
                    background: black;

                    .logo--light {
                        display: none;
                    }

                    .buy-tickets-btn {
                        color: white;
                    }

                    .warp-footer {
                        border-color: map-get($colors, gray-600);
                        color: map-get($colors, gray-200);

                        a {
                            color: map-get($colors, gray-200);
                        }
                    }

                    .space__title {
                        color: map-get($colors, gray-100);
                    }

                    .space__target {
                        color: map-get($colors, gray-400);
                    }

                    .marked {
                        color: map-get($colors, gray-200);
                    }

                    .cart-btn--disabled:disabled {
                        background: map-get($colors, blue-gray-900);
                        color: map-get($colors, blue-gray-800);
                        border: none;
        
                        path {
                            stroke: map-get($colors, blue-gray-800);
                        }
                    }

                    hr {
                        color: map-get($colors, gray-700);
                        background-color: map-get($colors, gray-700);
                    }

                    .space-endpoint {
                        background-color: map-get($colors, gray-900);
                        border-color: map-get($colors, gray-900);
                    }

                    .space-endpoint__title {
                        color: map-get($colors, gray-100);
                    }

                    .space-endpoint__date {
                        color: map-get($colors, gray-400);
                    }

                    .space-endpoint__price {
                        color: map-get($colors, gray-100);
                    }

                    .space-endpoint__btn {
                        color: map-get($colors, primary-500);

                        path {
                            stroke: map-get($colors, primary-500);
                        }
                    }

                    .space-endpoint__btn--empty {
                        border-color: map-get($colors, gray-700);
                    }

                    .space-endpoint__btn--entry {
                        border-color: map-get($colors, gray-800);
                    }

                    .space-endpoint__btn--disabled {
                        border-color: map-get($colors, gray-700);
                        pointer-events: none;
                        color: map-get($colors, gray-600);
                    }

                    .space__social-icon {
                        path {
                            fill: white;
                        }
                    }
                }

                @media only screen and (max-width: 767px) {
                    .hide-mobile {
                        display: none;
                    }
                }
                
                @media only screen and (min-width: 768px) {
                    .mw768-height-2dot5rem {
                      height: 2.5rem;
                    }
              
                    .mw768-display-block {
                      display: block;
                    }
                    .mw768-flex-order-1 {
                        order: 1;
                    }
                    .mw768-flex-order-2 {
                        order: 2;
                    }
                    .mw768-max-height-37dot5rem {
                        max-height: 37.5rem;
                    }
                    .mw768-max-width-76rem {
                        max-width: 76rem;
                    }
                }
            }
        }
    }
}